import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/firestore'
import firebase from 'gatsby-plugin-firebase'
import { appConfig } from '../appConfig'

if (appConfig.ENVIRONMENT === 'local') {
  firebase.functions().useFunctionsEmulator('/local-firebase-functions')
  firebase.firestore().settings({
    host: 'localhost:8080',
    ssl: false,
  })
}
