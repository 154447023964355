import * as Sentry from '@sentry/browser'
import { logger } from './logger'
import { appConfig } from '../appConfig'
import { captureExceptionWithLabel } from './sentry'

export const hasLocalStorage = () => {
  try {
    return !!window.localStorage
  } catch (e) {
    Sentry.captureMessage(
      'Encountered browser with localStorage in the DOM, but errors when accessed',
    )
    captureExceptionWithLabel(e, 'hasLocalStorage')
    return false
  }
}

export const hasSessionStorage = () => {
  try {
    return !!window.sessionStorage
  } catch (e) {
    Sentry.captureMessage(
      'Encountered browser with sessionStorage in the DOM, but errors when accessed',
    )
    captureExceptionWithLabel(e, 'hasSessionStorage')
    return false
  }
}

// https://stackoverflow.com/questions/5899087/android-webview-localstorage
if (appConfig.IS_BROWSER && !hasLocalStorage()) {
  Sentry.captureMessage('client without localStorage detected')
  logger.info('using localStorage polyfill')
}

class LocalStoragePolyfill {
  inMemoryCache: Record<string, string> = {}
  getItem(sKey: string) {
    if (!sKey || !this.hasOwnProperty(sKey)) {
      return null
    }
    return this.inMemoryCache[sKey]
  }
  setItem(sKey: string, sValue: string) {
    if (!sKey) {
      return
    }
    this.inMemoryCache[sKey] = sValue
  }
  removeItem(sKey: string) {
    if (!sKey || !this.hasOwnProperty(sKey)) {
      return
    }
    delete this.inMemoryCache[sKey]
  }
}

export const localStoragePolyfill = new LocalStoragePolyfill()
