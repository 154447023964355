import * as Sentry from '@sentry/browser'
import { hasLocalStorage, hasSessionStorage, localStoragePolyfill } from './localStoragePolyfill'
import { appConfig } from '../appConfig'
import { captureExceptionWithLabel } from './sentry'

interface IStoredValue {
  value: any
  datetimeStored: string
}

let usedLocalStorage =
  appConfig.IS_BROWSER && hasLocalStorage() ? window.localStorage : localStoragePolyfill
let usedSessionStorage =
  appConfig.IS_BROWSER && hasSessionStorage() ? window.sessionStorage : localStoragePolyfill

const parseStorageValue = (storedValue: null | string) => {
  if (!storedValue) {
    return null
  }
  return (JSON.parse(storedValue) as IStoredValue).value
}

const serializeStorageValue = (value: any) => {
  return JSON.stringify({ value, datetimeStored: new Date().toISOString() })
}

// some browsers will have localStorage in the DOM but error when accessing it
// https://stackoverflow.com/questions/21159301/quotaexceedederror-dom-exception-22-an-attempt-was-made-to-add-something-to-st
const tryToUseLocalStorage = (fn: () => any) => {
  try {
    return fn()
  } catch (e) {
    Sentry.captureMessage(
      'Encountered browser with localStorage in the DOM, but errors when accessed',
    )
    captureExceptionWithLabel(e, 'tryToUseLocalStorage')
    usedLocalStorage = localStoragePolyfill
    usedSessionStorage = localStoragePolyfill
    return fn()
  }
}

export const localCache = {
  getItem<T>(key: string): T | null {
    return parseStorageValue(tryToUseLocalStorage(() => usedLocalStorage.getItem(key)))
  },
  setItem(key: string, value: any) {
    const serializedValue = serializeStorageValue(value)
    tryToUseLocalStorage(() => usedLocalStorage.setItem(key, serializedValue))
  },
  removeItem(key: string) {
    tryToUseLocalStorage(() => usedLocalStorage.removeItem(key))
  },
}

export const sessionCache = {
  getItem<T>(key: string): T | null {
    return parseStorageValue(tryToUseLocalStorage(() => usedSessionStorage.getItem(key)))
  },
  setItem(key: string, value: any) {
    const serializedValue = serializeStorageValue(value)
    tryToUseLocalStorage(() => usedSessionStorage.setItem(key, serializedValue))
  },
}
