import { darken } from 'polished'
const baseGray = '#EDF1F2'

export const baseColors = {
  white: '#fff',
  black: '#0D1418',
  gray: [
    baseGray,
    darken(0.1)(baseGray),
    darken(0.2)(baseGray),
    darken(0.3)(baseGray),
    darken(0.4)(baseGray),
    darken(0.5)(baseGray),
    darken(0.6)(baseGray),
    darken(0.7)(baseGray),
    darken(0.9)(baseGray),
  ],
  lightBlue: '#475767',
  darkBlue: '#2B3D4F',
  blue: '#1B4965',
  purple: '#533A7B',
  pink: '#C0A9B0',
  red: '#BA1200',
  orange: '#DA8A6C',
  yellow: '#C9B79C',
  green: '#2B6F52',
  lightGreen: '#3DC9A0',
}

export const colors = {
  ...baseColors,
  text: baseColors.gray[8],
  darkBlueText: '#39495A',
  lightText: '#808D8E',
  link: '#39495A',
  error: '#BA1200',
  border: baseColors.gray[2],
  grayDark: baseColors.gray[8],
  background: baseColors.gray[0],
  secondary: baseColors.gray[6],
  muted: baseColors.gray[3],
  success: baseColors.green,
  info: baseColors.darkBlue,
  danger: baseColors.red,
  light: baseColors.gray[1],
  dark: baseColors.gray[8],
  textMuted: baseColors.gray[6],
}

export const space = [0, 2, 4, 8, 16, 32, 64, 128]

export const numericBreakpoints = [350, 576, 768, 992, 1200]
export const breakpoints = numericBreakpoints.map((num) => `${num}px`)

const body = '"Lato", sans-serif'
export const fonts = {
  body,
  sans: body,
  heading: '"Montserrat", sans-serif',
}

export const fontWeights = {
  heading: 300,
  display: 300,
  light: 300,
  body: 400,
  normal: 400,
  bold: 700,
}

export const fontSizes = [
  '0.75rem', // 0
  '0.875rem', // 1
  '1rem', // 2
  '1.25rem', // 3
  '1.5rem', // 4
  '1.75rem', // 5
  '2rem', // 6
  '2.5rem', // 7
  '3.5rem', // 8
  '4.5rem', // 9
  '5.5rem', // 10
  '6rem', // 11
]

export const lineHeights = {
  body: 1.5,
  heading: 1.2,
}

export const sizes = {
  // container widths
  sm: 540,
  md: 720,
  lg: 960,
  xl: 1140,
  container: 1140,
}

export const radii = {
  default: '0.25rem',
  sm: '4px',
  lg: '12px',
  pill: '50rem',
}

export const shadows = {
  default: '0 .5rem 1rem rgba(0, 0, 0, .15)',
  sm: '0 .125rem .25rem rgba(0, 0, 0, .075)',
  lg: '0 1rem 3rem rgba(0, 0, 0, .175)',
}

const heading = {
  fontFamily: 'heading',
  fontWeight: 'heading',
  lineHeight: 'heading',
}
const display = {
  fontWeight: 'display',
  lineHeight: 'heading',
}

// variants
const typeStyles = {
  heading,
  display,
}

const headers = {
  h1: {
    ...heading,
    fontSize: [5, 6, 7],
  },
  h2: {
    ...heading,
    fontSize: [4, 5, 6],
  },
  h3: {
    ...heading,
    fontSize: [3, 4, 5],
  },
  h4: {
    ...heading,
    fontSize: 3,
  },
  h5: {
    ...heading,
    fontSize: 3,
  },
  h6: {
    ...heading,
    fontSize: 2,
  },
  h7: {
    ...heading,
    fontSize: 2,
  },
}

export const styles = {
  ...headers,
  root: {
    fontFamily: 'body',
    lineHeight: 'body',
    fontWeight: 'body',
  },
  a: {
    color: 'link',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
  },
  p: {
    mb: 3,
    lineHeight: 'body',
  },
  blockquote: {
    fontSize: 3,
    mb: 3,
  },
  table: {
    // todo
    width: '100%',
    marginBottom: 3,
    color: 'gray.9',
    borderCollapse: 'collapse',
  },
  th: {
    verticalAlign: 'bottom',
    borderTopWidth: 2,
    borderTopStyle: 'solid',
    borderTopColor: 'gray.3',
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: 'gray.3',
    padding: '.75rem',
    textAlign: 'inherit',
  },
  td: {
    borderBottomWidth: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: 'gray.3',
    verticalAlign: 'top',
    padding: '.75rem',
  },
  inlineCode: {
    color: 'pink',
  },
  img: {
    maxWidth: '100%',
    height: 'auto',
  },
}

export const theme = {
  borders: ['1px solid black'],
  breakpoints,
  colors,
  space,
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  sizes,
  shadows,
  radii,
  typeStyles,
  styles,
  text: {
    heading: styles.h1,
    ...headers,
  },
  minSize: {
    sm: breakpoints[0],
    md: breakpoints[1],
    lg: breakpoints[2],
    xl: breakpoints[3],
  },
}
