import _ from 'lodash'
import { appConfig } from '../appConfig'
import { localCache } from './browserStorage'
import { Sentry, captureExceptionWithLabel } from './sentry'

declare global {
  interface Window {
    globalDebugger: any
  }
}

const getBool = (key: string) => {
  const item = localCache.getItem<boolean>(key)
  if (item === null) {
    return false
  }
  return item
}

const toggleBool = (key: string, currentVal: boolean) => {
  localCache.setItem(key, !currentVal)
  return !currentVal
}

export const globalDebugger = {
  _,
  appConfig,
  showLogs: getBool('showLogs'),
  toggleShowLogs() {
    toggleBool('showLogs', this.showLogs)
    this.showLogs = !this.showLogs
  },
  testSentry: () => captureExceptionWithLabel(new Error('testing error'), 'testing error'),
  hasTestingEnvironmentUX: getBool('hasTestingEnvironmentUX'),
  toggleHasTestingEnvironmentUX() {
    toggleBool('hasTestingEnvironmentUX', this.hasTestingEnvironmentUX)
    this.hasTestingEnvironmentUX = !this.hasTestingEnvironmentUX
  },
  clearCookies: () => {
    const cookies = document.cookie.split(';')
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i]
      const eqPos = cookie.indexOf('=')
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
    }
  },
}

if (appConfig.IS_BROWSER) {
  window.globalDebugger = globalDebugger
}
