import { appConfig } from '../appConfig'
import { globalDebugger } from './globalDebugger'
import * as Sentry from '@sentry/browser'

const consoleToSentryLevelMap: Record<string, Sentry.Severity> = {
  log: Sentry.Severity.Info,
  warn: Sentry.Severity.Warning,
}

const log = (level: 'log' | 'warn' | 'debug') => (
  str: string,
  category?: string,
  ...data: Record<string, any>[]
) => {
  if (appConfig.ENVIRONMENT !== 'production' || globalDebugger.showLogs) {
    console[level === 'debug' ? 'log' : level](
      category ? `%c app:${category}:` : `app:`,
      'color: orange',
      str,
      ...data,
    )
  }
  Sentry.addBreadcrumb({
    category: category,
    level: consoleToSentryLevelMap[level] || Sentry.Severity.Info,
    message: str,
    data,
  })
}

class Logger {
  info = (str: string, category?: string, ...data: Record<string, any>[]) => {
    log('log')(str, category, ...data)
  }
  warn = (str: string, category?: string, ...data: Record<string, any>[]) => {
    log('warn')(str, category, ...data)
  }
  debug = (str: string, category?: string, ...data: Record<string, any>[]) => {
    log('debug')(str, category, ...data)
  }
}

export const logger = new Logger()
