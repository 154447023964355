// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-demo-game-tsx": () => import("./../src/pages/demo-game.tsx" /* webpackChunkName: "component---src-pages-demo-game-tsx" */),
  "component---src-pages-game-tsx": () => import("./../src/pages/game.tsx" /* webpackChunkName: "component---src-pages-game-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-new-game-tsx": () => import("./../src/pages/new-game.tsx" /* webpackChunkName: "component---src-pages-new-game-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

