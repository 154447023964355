import * as SentryLib from '@sentry/browser'
import { appConfig } from '../appConfig'
import { logger } from './logger'

const getEventMessage = (event: SentryLib.Event) => {
  if (appConfig.ENVIRONMENT !== 'local') {
    return ''
  }
  if (event.message) {
    return event.message
  }
  if (
    event.exception &&
    event.exception.values &&
    event.exception.values[0] &&
    event.exception.values[0].value
  ) {
    return event.exception.values[0].value
  }
  return ''
}

SentryLib.init({
  dsn: appConfig.SENTRY_DSN,
  logLevel: 2,
  environment: appConfig.ENVIRONMENT,
  enabled: appConfig.ENVIRONMENT !== 'local',
  beforeBreadcrumb(breadcrumb) {
    switch (breadcrumb.category) {
      case 'console':
        return null
      case 'xhr': {
        if (
          breadcrumb.data &&
          breadcrumb.data.url &&
          breadcrumb.data.url.indexOf('api.mixpanel.com') !== -1
        ) {
          return null
        } else {
          return breadcrumb
        }
      }
      default:
        return breadcrumb
    }
  },
  beforeSend: (event) => {
    if (appConfig.ENVIRONMENT === 'local') {
      if (event.extra && event.extra.exception && event.extra.exception.stacktrace) {
        logger.warn(event.extra.exception.stacktrace.join('\n'), 'error')
      } else {
        logger.warn(`SENTRY EVENT: ${getEventMessage(event)}`, 'error', event.tags!)
      }
    }
    return event
  },
})

export const Sentry = SentryLib

export const captureExceptionWithLabel = (error: Error, label: string, scope?: SentryLib.Scope) => {
  if (scope) {
    scope.setExtra('label', label)
    Sentry.captureException(error)
  } else {
    Sentry.withScope((scope) => {
      scope.setExtra('label', label)
      Sentry.captureException(error)
    })
  }
}
