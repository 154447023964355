import _ from 'lodash'

type Environment = 'local' | 'testing' | 'production'

const IS_BROWSER = typeof window !== 'undefined'
const getEnvironment = (): Environment => {
  if (!IS_BROWSER) {
    return 'production'
  }
  if (_.includes(window.location.href, 'ourpokergame-testing.')) {
    return 'testing'
  }
  if (_.includes(window.location.href, '8000')) {
    return 'local'
  }
  return 'production'
}

const environment = getEnvironment() as Environment
console.log(`environment: ${environment}`)

const getEnvironmentDependentConfig = () => {
  switch (environment) {
    case 'local': {
      return {
        SENTRY_DSN: 'https://7d1b4561f2d14000b50ba4816d9c5883@o385611.ingest.sentry.io/5218687',
      }
    }
    case 'testing': {
      return {
        SENTRY_DSN: 'https://7d1b4561f2d14000b50ba4816d9c5883@o385611.ingest.sentry.io/5218687',
      }
    }
    case 'production': {
      return {
        SENTRY_DSN: 'https://ccfde758ed664f9fba3be860af1aecad@o385611.ingest.sentry.io/5218689',
      }
    }
  }
}

export const appConfig = {
  ...getEnvironmentDependentConfig(),
  ENVIRONMENT: environment,
  IS_BROWSER,
}
